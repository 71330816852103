import React from 'react';
//import DateCountdown from 'react-date-countdown-timer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import config from '../../config';

const IndexPage = () => {

  /*
  <br /><br />
                  <DateCountdown dateTo='April 27, 2023 17:00:00 GMT-05:00' callback={() => alert('Hello')} />
                  */

  return (
    <Layout>
      <div className="container-fluid p-0" id="page-top">
        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="about"
        >
          <div className="w-100">
            <h1 className="mb-0">
              {config.firstName}
              <span className="text-primary">{config.lastName}</span>
            </h1>
            <div className="subheading mb-5">
              45 YEARS AND COUNTING...
            </div>
            <p className="mb-5">The Lambda Iota Alumni Association is the organization representing and promoting the
              Brotherhood represented by the Alumni of the Lambda Iota Chapter of Kappa Sigma. The Association is
              tasked with the representation of the members as a group and promoting its identified goals. The
              Association also coordinates all official gatherings of the Alumni.</p>
          </div>
        </section>

        <hr className="m-0" />

        <section className="resume-section p-3 p-lg-5 d-flex d-column" id="reunion">
          <div className="my-auto">
            <h2 className="mb-5">50th Reunion Weekend</h2>

            <div align="center">&nbsp;</div>

            <div className="resume-item d-flex flex-column flex-md-row mb-5">
              <div className="resume-content mr-auto">
                <h3 className="mb-0">Thursday, April 27th - Sunday, April 30th, 2023
                </h3>
              </div>
            </div>
            <div className="resume-item d-flex flex-column flex-md-row mb-5">
              <div className="resume-content mr-auto">
                <p className="mb-5">Brothers,
                  <br />
                  <br />
                  Mark your calendars. This is not the reunion to miss! Thursday, April 27th - Sunday, April
                  30th, 2023 we will be celebrating 50 glorious years of Brotherhood!<br /><br />
                  Join 5 decades of fellow Brothers for Food, Fun and Fellowship right back where
                  it all started - our alma mater. As Alumni Association President, I can assure you that your
                  Executive Committee is working hard to develop an official event agenda and budget. We will
                  utilize this website and our Facebook page to keep you all up-to-date. There are a few key
                  points I will address now. At the 45th, where we first experienced campus without 6&8, the
                  school did not provide us with an adequate central location for barbequing and hanging out.
                  For the 50th, we have reserved the Highland House near campus for all such festivities and
                  trust this to be a very adequate location for our Saturday business meeting as well. Regarding the Saturday night banquet, some will remember the Cow Palace location from the
                  40th and 45th. This venue is no longer available to host such events but trust we are
                  working on a closer and comparable option for the Lambda Iota 50th Celebration
                  Banquet.<br /><br />
                  Certainly this 50th reunion will mark years of close-knit brotherhood amongst a quality
                  group of men. But moreover, this event will celebrate 50 years of dedication, devotion,
                  guidance and love exhibited by the greatest Faculty Advisor we could have ever asked for.
                  This 50th reunion will be a celebration of Dr. Terrell Tebbetts!<br /><br />
                  Again, we will keep you all updated as things develop and certainly welcome any
                  input and feedback.<br /><br />
                  Thank you and AEKDB!<br /><br />
                  Landon Downing<br />
                  President<br />
                  Lamba-Iota Alumni Association</p>
              </div>
            </div>
          </div>
        </section>

        <hr className="m-0" />

        <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="connect">
          <div className="my-auto">
            <h2 className="mb-5">Connect with us on Facebook</h2>
            <p>Join the group on Facebook and stay in the conversation all the time!<br /><br />
              <a href="https://www.facebook.com/groups/131123853630713/" className="btn btn-default btn-lg"><FontAwesomeIcon icon={faFacebook} /> <span className="network-name">Facebook Group</span></a></p>
          </div>
        </section>

        <hr className="m-0" />

        <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="contact">
          <div className="my-auto">
            <h2 className="mb-5">Contact Us</h2>
            <p>Email us at <a href="mailto:lialumniassociation@gmail.com">lialumniassociation@gmail.com</a>.</p>
            <p className="mb-0">You can also reach us at the following address:<br /><br />
              Lambda Iota Alumni Association<br />
              Attn: Watson Neal, Secretary<br />
              535 Lynn Drive<br />
              Pea Ridge, AR 72751</p>
          </div>
        </section>
      </div>
      <script src="js/resume.min.js"></script>
      <script src="js/sample.js"></script>

    </Layout>
  )
};

export default IndexPage;
